import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import Banner from "../../components/RTC/Banner"
import Cases from "../../components/CommonComponents/Cases"
import ContactUsExpertise from "../../components/CommonComponents/ContactUsExpertise"
import { mainUrl } from "../../js/config"
import dataForProjects from "../../information/RTC/dataForProjects.json"
import Recognizing from "../../components/RTC/Recognizing"
import Necessary from "../../components/RTC/Necessary"
import Experience from "../../components/SingleService/Common/Experience"
import Solutions from "../../components/RTC/Solutions"
import Choose from "../../components/RTC/Choose"
import Keys from "../../components/RTC/Keys"
import Technologies from "../../components/RTC/Technologies"
import dataForRtc from "../../information/RTC/dataForRtc"

const settings = {
  dots: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1021,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: "center",
      },
    },
  ],
}

export default function RTC() {
  const sendUrl = typeof window !== "undefined" ? window?.location?.href : ""
  const { ogImage } = dataForRtc;
  return (
    <div>
      <SEO
        title="WebRTC Application Development Services | OS-System"
        description="Develop secure and scalable WebRTC solutions with OS-System. We build real-time video, voice, and messaging applications for healthcare, finance, e-commerce, and more. WebRTC app development services Seamless cross-platform communication with low latency and high-quality streaming."
        canonical={`${mainUrl}/expertise/rtc/`}
        ogImage={ogImage}
      />
      <Layout showFormPoint={100}>
        <Banner />
        <Recognizing />
        <Experience />
        <Necessary />
        <Solutions />
        <Choose />
        <Keys />
        <Technologies />
        <Cases
          projects={dataForProjects}
          settings={settings}
          headerText="Work Projects"
        />
        <ContactUsExpertise form={0} url={sendUrl} />
      </Layout>
    </div>
  )
}
